.ag-icon-checkbox-checked {
  color: rgb(10, 122, 130) !important;
}

.ag-tool-panel-wrapper {
  width: 100%;
}

.ag-theme-views .ag-column-drop-row-group .ag-column-drop-icon {
  display: block;
}

.ag-theme-views .ag-cell {
  line-height: 4.3rem;
  padding: 0px 1rem;
}

.ag-theme-views .ag-checkbox-input-wrapper.ag-checked::after {
  color: #2e8fc2;
}

.ag-theme-views .ag-toggle-button-input-wrapper.ag-checked {
  background-color: #2e8fc2;
  border-color: #2e8fc2;
}

.ag-theme-views .ag-toggle-button-input-wrapper.ag-checked::before {
  border-color: #2e8fc2;
}
