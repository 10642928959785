/*
    Note: When importing the stylesheet from FOS-stles in the GridDetail component, global styling is applied.
    That styling uses font-size 62.5%. We DO want that application wide for rem scaling, which is why we have the code below
    in case that styling is removed from GridDetail
*/

body {
  font-size: 1.6rem; /* keep standard browser agent font size of 16px*/
  margin: 0;
  padding: 0;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
}
